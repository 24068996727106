

















































import { mapMutations, mapState } from 'vuex';
import ScannerMixin from '@/mixins/ScannerMixin';
import { EnumsActions, EnumsIcons } from '@/store/modules/globals/types';
import LastEdited from '@/components/ui/LastEdited.vue';
import RepositoryFactory from '@/services/RepositoryFactory';
import IBoxesRepository from '@/services/Repositories/Boxes/types';

const factory = new RepositoryFactory();

export default ScannerMixin.extend({
  name: 'ReboxScanner',
  components: {
    DefaultLayout: () => import('@/layouts/Default.vue'),
    TheFabButton: () => import('@/components/ui/TheFabButton.vue'),
    BasePopup: () => import('@/components/ui/BasePopup.vue'),
  },
  data() {
    return {
      confirmReturnPopup: false,
      reboxId: '',
      boxStatus: '',
    };
  },
  computed: {
    ...mapState('lastEdited', ['editedBoxes']),
    ...mapState('globals', ['registeredBoxes', 'isBoxRegistrationComponent']),
    boxesRepository(): IBoxesRepository {
      return factory.get('boxes') as IBoxesRepository;
    },
  },
  methods: {
    ...mapMutations('globals', [
      'SET_TITLE',
      'SET_ACTION',
      'SET_ICON',
      'CLEAR_BOXSTATES',
      'SET_REGISTER_BOXES',
      'SET_ISBOXREGISTRATIONCOMPONENT',
    ]),
    async registerBox(reboxId: string) {
      const { data } = await (this as any).boxesRepository.registerBox(reboxId);
      (this as any).SET_REGISTER_BOXES(reboxId);
    },
  },
  created() {
    (this as any).SET_TITLE('header.box-registration');
    (this as any).SET_ACTION(EnumsActions.processes);
    (this as any).SET_ICON(EnumsIcons.processes);
    (this as any).SET_ISBOXREGISTRATIONCOMPONENT(true);
  },
  destroyed() {
    (this as any).SET_ISBOXREGISTRATIONCOMPONENT(false);
  },
});
